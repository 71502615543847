<template>

	<div>
		<van-nav-bar class="navbar" title="业绩详情" fixed left-arrow @click-left="leftReturn" />
		<!-- 第一部分 -->
		<div class="header">
			<div class="leftStatus1">
				已结算
			</div>
			<div class="title">
				<span class="s1">{{houseInfo.houseTypeStr}}</span>
				<span class="s1"> · </span>
				<span class="s2">{{houseInfo.roomDetailedAddress}}</span>
			</div>
			<div class="headDetail">
				<div class="h-line">
					<span class="h-title">房源编号：</span>
					<span class="h-detail-house-code">{{houseInfo.houseCode}}</span>
				</div>
				<div class="h-line">
					<span class="h-title">单间数量：</span>
					<span class="h-detail">{{houseInfo.houseCount?houseInfo.houseCount:0}}间</span>
				</div>
				<div class="h-line">
					<span class="h-title">委托日：</span>
					<span class="h-detail">{{houseInfo.contractBeginTime}}</span>
				</div>
				<div class="h-line">
					<span class="h-title">业主交房日：</span>
					<span class="h-detail">{{houseInfo.giveHouseTime}}</span>
				</div>
				<div class="h-line">
					<span class="h-title">收房年限：</span>
					<span class="h-detail">{{houseInfo.contractYears?houseInfo.contractYears:0}}年</span>
				</div>
				<div class="h-line">
					<span class="h-title">总免租期：</span>
					<span class="h-detail">{{houseInfo.contractNoFeeDays?houseInfo.contractNoFeeDays:0}}天</span>
				</div>
			</div>
		</div>
		<!-- 第二部分 -->
		<div class="smcost">成本信息</div>
		<div class="middle">
			<div class="middledetail">
				<div class="h-line">
					<span class="h-title" style="color: #FF5D3B;">总成本：</span>
					<span class="m-detail" style="color: #FF5D3B;">{{houseInfo.dayCost?houseInfo.dayCost:0}}元/日</span>
				</div>
				<div class="h-line">
					<span class="h-title">租金日成本：</span>
					<span class="m-detail">{{houseInfo.rentMoneyDayCost?houseInfo.rentMoneyDayCost:0}}元/日</span>
				</div>
				<div class="h-line">
					<span class="h-title">装修日成本：</span>
					<span class="m-detail">{{houseInfo.renovationDayCost?houseInfo.renovationDayCost:0}}元/日</span>
				</div>
			</div>
			<div class="headDetail">
				<div class="h-line">
					<span class="h-title">收房价格：</span>
					<span class="h-detail" style="margin-right: 8px;">{{houseInfo.rentMoney?houseInfo.rentMoney:0}}元</span>
<!--					<span class="edit"><img class="editimg" src="../../../assets/images/Editing.png" alt=""></span>-->
				</div>
				<div class="h-line">
					<span class="h-title">合同类型：</span>
					<span class="h-detail" style="margin-right: 8px;">{{houseInfo.noFeeTypeStr}}</span>
					<!--					<span class="edit"><img class="editimg" src="../../../assets/images/Editing.png" alt=""></span>-->
				</div>
				<div class="h-line">
					<span class="h-title">复利比例：</span>
					<span class="h-detail" style="margin-right: 8px;">{{houseInfo.renovationInterest}}</span>
<!--					<span class="edit"><img class="editimg" src="../../../assets/images/Editing.png" alt=""></span>-->
				</div>
				<div class="h-line">
					<span class="h-title">装修费用：</span>
					<span class="h-detail" style="margin-right: 8px;">{{houseInfo.renovationCost?houseInfo.renovationCost:0}}元</span>
<!--					<span class="edit"><img class="editimg" src="../../../assets/images/Editing.png" alt=""></span>-->
				</div>
				<div class="h-line">
					<span class="h-title">装修分摊截止日：</span>
					<span class="h-detail" style="margin-right: 8px;">{{houseInfo.renovationShareEndTime}}</span>
<!--					<span class="edit"><img class="editimg" src="../../../assets/images/Editing.png" alt=""></span>-->
				</div>
				<div class="h-line">
					<span class="h-title">成本开始日期：</span>
					<span class="h-detail" style="margin-right: 8px;">{{houseInfo.contractBeginTimeDate}}</span>
					<!--					<span class="edit"><img class="editimg" src="../../../assets/images/Editing.png" alt=""></span>-->
				</div>
			</div>
		</div>
		<!-- 第三部分  房间列表 -->
		<div v-for="(item,index) in houseInfo.roomHouseList" :v-key="index">
			<div class="btom" v-if="houseInfo.roomAchievementMap && houseInfo.roomAchievementMap[item.id]">
				<div class="smcost2">{{'房间' + item.roomName}}</div>
				<div class="headDetail" style="padding-bottom: 5px;">
					<div class="h-line">
						<span class="h-title">出房编号：</span>
						<span class="h-detail-house-code">{{houseInfo.roomAchievementMap[item.id][0].contractCode}}</span>
					</div>
					<!--<div class="h-line">-->
						<!--<span class="h-title">签约人：</span>-->
						<!--<span class="h-detail">{{houseInfo.signStaffName}}</span>-->
					<!--</div>-->
					<!--<div class="h-line">-->
						<!--<span class="h-title">合作人：</span>-->
						<!--<span class="h-detail">{{houseInfo.partnerStaffName}}</span>-->
					<!--</div>-->
				</div>
				<div class="room">
					<div class="rm1">
						<div class="h-line">
							<span class="h-title">出房价格：</span>
							<span class="h-detail">{{houseInfo.roomAchievementMap[item.id][0].rentMoney?houseInfo.roomAchievementMap[item.id][0].rentMoney:0}}元</span>
						</div>
						<div class="h-line">
							<span class="h-title">签约时长：</span>
							<span class="h-detail">{{houseInfo.roomAchievementMap[item.id][0].rentTimeLong?houseInfo.roomAchievementMap[item.id][0].rentTimeLong:0}}天</span>
						</div>
					</div>
					<div class="rm1">
						<div class="h-line">
							<span class="h-title">出房周期：</span>
							<span class="h-detail">{{houseInfo.roomAchievementMap[item.id][0].circleTime?houseInfo.roomAchievementMap[item.id][0].circleTime:0}}天</span>
						</div>
						<div class="h-line">
							<span class="h-title">单间收益：</span>
							<span class="h-detail">{{houseInfo.roomAchievementMap[item.id][0].roomDailyProfit?houseInfo.roomAchievementMap[item.id][0].roomDailyProfit:0}}元</span>
						</div>
					</div>
				</div>
				<div class="headDetail" style="padding-bottom: 10px;">
					<div class="h-line">
						<span class="h-title">出房周期业绩：</span>
						<span class="h-detail">{{houseInfo.roomAchievementMap[item.id][0].circleTimeAchievement?houseInfo.roomAchievementMap[item.id][0].circleTimeAchievement:0}}元</span>
					</div>
					<div class="h-line">
						<span class="h-title">免租期业绩：</span>
						<span class="h-detail">{{houseInfo.roomAchievementMap[item.id][0].norentAchievement?houseInfo.roomAchievementMap[item.id][0].norentAchievement:0}}元</span>
					</div>
					<div class="h-line">
						<span class="h-title">付租期业绩：</span>
						<span class="h-detail">{{houseInfo.roomAchievementMap[item.id][0].rentAchievement?houseInfo.roomAchievementMap[item.id][0].rentAchievement:0}}元</span>
					</div>
					<div class="h-line">
						<span class="h-title">出房扣减业绩：</span>
						<span class="h-detail">{{houseInfo.roomAchievementMap[item.id][0].cutAchievement?houseInfo.roomAchievementMap[item.id][0].cutAchievement:0}}元</span>
					</div>
					<div class="h-line">
						<span class="h-title">单间总业绩：</span>
						<span class="h-detail">{{houseInfo.roomAchievementMap[item.id][0].realAchievement?houseInfo.roomAchievementMap[item.id][0].realAchievement:0}}元</span>
					</div>
				</div>
			</div>
			<div class="btom" v-else>
				<div class="smcost2">{{'房间' + item.roomName}}</div>
			</div>
		</div>
<!--		房源总业绩-->
		<div class="smcost">房源总业绩</div>
		<div class="middle">
			<div class="middledetail">
				<div class="h-line">
					<span class="h-title">渠道费用：</span>
					<span class="h-detail">{{channelFee?channelFee:0}}元</span>
				</div>
				<div class="h-line">
					<span class="h-title">其他扣除：</span>
					<span class="h-detail">{{otherFee?otherFee:0}}元</span>
				</div>
				<div class="h-line">
					<span class="h-title">实际业绩：</span>
					<span class="h-detail">{{realAllAchievement}}元</span>
				</div>
			</div>
		</div>

		<!-- 垫底的 -->
		<div style="width: 100%;height: 50px;"></div>
	</div>
</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
	} from "vant";
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(PullRefresh);
	Vue.use(Popup);
	Vue.use(PullRefresh);
	import {
		achievementDetail,
		queryBaseData,
		userLeaseChangeList
	} from "../../../getData/getData";
	import {
		getStaffId,
		getUserId,
		globaluserId,
		responseUtil
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'

	export default {
		name: "myOrder",
		data() {
			return {
				show: false,
				showPicker: false,
				isOccupationShow: false,
				loading: false,
				finished: false,
				certificateType: '',
				defaultIndex: '',
				swi: true,
				isLoading: false,
				totalPage: '',
				houseInfo:{},
				channelFee:'',
				otherFee:'',
				realAchievement:'',
                realAllAchievement:''
			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			dialMobile,
			rxNavBar

		},
		created() {
		},
		//初始化
		mounted() {
			debugger
			this.id = this.$route.query.id
			this.staffId = this.$route.query.staffId
			this.staffAchievementDetailId = this.$route.query.staffAchievementDetailId
			this.initData();
		},
		methods: {
			//返回上一页
			leftReturn() {
				this.$router.go(-1)
			},
			initData(){
				let that = this;
				let initData = {};
				initData.user_id = getStaffId();
				initData.achievement_id = that.id;
                initData.staffAchievementDetailId = that.staffAchievementDetailId;
				achievementDetail(initData).then(response => {
					responseUtil.dealResponse(that, response, () => {
						debugger
						console.log(response.data.data)
						that.houseInfo = response.data.data;
						that.houseInfo.houseCount = response.data.data.roomHouseList.length
						that.houseInfo.roomHouseList = response.data.data.roomHouseList
						that.channelFee = response.data.data.channelFee
						that.otherFee = response.data.data.otherFee
						that.realAchievement = response.data.data.realAchievement

						that.addTitle(that.houseInfo.roomHouseList);
						that.allAchievement();
					});
				});

			},
			//计算实际业绩
			allAchievement () {
				this.realAllAchievement = this.houseInfo.allRoomHouseAchievement - Number(this.channelFee) - Number(this.otherFee)
			},

			addTitle(roomHouseList){
				for(let i = 0; i < roomHouseList.length; i++){
					this.houseInfo.roomHouseList[i].roomName = this.getTitle(roomHouseList[i]);
				}
			},
			getTitle: function (item) {
				var roomRatio = 0
				if(item.roomRatio){
					roomRatio = item.roomRatio * 100 + '%'
				}
				var dailyCost = 0
				if(this.houseInfo.roomAchievementMap && this.houseInfo.roomAchievementMap[item.id] && this.houseInfo.roomAchievementMap[item.id].dailyCost){
					dailyCost = this.houseInfo.roomAchievementMap[item.id].dailyCost
				}
				var title = item.roomName + '-' + roomRatio + '   日成本：'+ dailyCost + '元/天'
				return title
			},

		},

	}
</script>

<style scoped>
	.navbar {
		background-color: white;
		border-bottom: 2px solid white;
	}

	.header,.middle,.btom{
		border-radius: 8px;
		width: 90%;
		background-color: white;
		margin: 0 auto;
		margin-top: 80px;
	}

	.btom {
		min-height: 20px;
	}

	.middle,.btom{margin-top: 0px;}

	.botm{
		border-bottom: 1px solid #f4f4f4;
		margin-bottom: 150px;
	}

	.leftStatus1 {
		width: 43px;
		height: 16px;
		line-height: 16px;
		color: rgba(255, 255, 255, 1);
		font-size: 10px;
		text-align: center;
		/* float: left; */
		border-top-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.leftStatus1 {
		background-image: linear-gradient(to right, #66CB94, #28D3B0);
	}

	.title{
		padding-top: 8px;
		padding-left: 25px;
		padding-right: 25px;
		padding-bottom: 12px;
		border-bottom: 1px solid #f4f4f4;
	}

	.s1{
		font-weight: 600;
		color: #FF5D3B;
		font-size: 15px;
	}

	.s2{
		font-weight: 600;
		font-size: 15px;
	}

	.headDetail,.middledetail{
		margin-top: 10px;
		padding-left: 25px;
		padding-bottom: 25px;
	}

	.middledetail{
		padding-top: 15px;
		border-bottom: 1px solid #f4f4f4;
		padding-bottom: 10px;
	}

	.h-line{
		position: relative;
		margin-bottom: 4px;
	}

	.h-title{
		font-weight: 600;
		font-weight: bold;
		font-size: 14px;
	}

	.h-detail{
		font-size: 12px;
		color: #ACACAC;
	}
	.h-detail-house-code{
		font-size: 12px;
		color:  #2276D2;
		text-decoration:underline;
	}

	.m-detail{
		font-weight: 600;
		font-size: 14px;
	}

	.smcost{
		color: #FF5D3B;
		margin-left: 20px;
		font-weight: 600;
		font-size: 12px;
		margin-top: 20px;
	}

	.smcost2{
		color: #FF5D3B;
		margin-top: 20px;
		font-size: 14px;
		padding-top: 15px;
		padding-left: 15px;
		padding-bottom: 15px;
		font-weight: 600;
		border-bottom: 1px solid #f4f4f4;
	}

	.room{
		border-top: 1px solid #f0f0f0;
		padding-left: 25px;
		padding-top: 10px;
		padding-bottom: 20px;
		border-bottom: 1px solid #f0f0f0;
		height: 90px;
		/* background-color: red; */
	}

	.rm1{
		width: 48%;
		float: left;
		height: 90px;
		/* background-color: red; */
	}

	.edit{
		position: absolute;
		top: 3px;
	}

	.editimg{
		width: 15px;
		height: 15px;
	}
</style>
